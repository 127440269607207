/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #7e9ed4;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #2653A2;
  border-radius: 2px;
}

/* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 5px;
}

.common_container {
  padding-inline: 9rem;
}

.head_container {
  background-color: #fff;
  box-shadow: 0px 10px 20px #0000000D;
  position: sticky;
  top: 0;
  z-index: 100;
}

.react__icon {
  font-size: 3.375rem;
  font-weight: 900;
  color: #fff
}

.header__div {
  justify-content: space-between;
  display: flex;
  align-items: center;
}

.logo {
  height: 100px;
  position: sticky;
  /* padding: 3px 0px; */
  margin-bottom: 8px;

}

.heading_detail {
  display: flex;
  justify-content: space-between;

}

.download_button {
  height: 37px;
  width: 100px;
  display: flex;
  gap: 1rem;
}

.heading:hover {
  color: #2653A2;
  -webkit-transform: scale(1.2);
  -ms-transform: scale(1.2);
  transform: scale(1.2);
  transition: 1s ease;
}

.heading {
  transition: 1s ease;
  font-size: 15px;
  text-decoration: none;
  color: #000;
  /* font-weight: 600; */
  display: flex;
  cursor: pointer;
  flex-direction: column;
}

.background_img {
  height: 90vh;
  background-image: url('../assets//first_crousel_Img.png');
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}




.editor_choice {
  color: #fff;
  font-size: 1.3rem;
  background-color: #2653A2;
  border-radius: 40px;
  width: 22%;
  margin: auto;
  padding: 4px 0px;

}

.swap_text {
  text-align: center;
  color: #FFFFFF;
  position: absolute;
  top: 45%;
  width: 100%
}

.text_para {
  font-size: 1rem;

}

.try_button {
  color: white;
  height: 3rem;
  width: 10rem;
  margin-top: 35px;
  border: none;
  background-color: #2653A2;
  border-radius: 40px;
  font-weight: 500px;
  transition: 1s ease;

}

.try_button:hover {
  background-color: #628ace;
  -webkit-transform: scale(1.1);
  -ms-transform: scale(1.1);
  transform: scale(1.1);
  transition: 1s ease;
}

.heaing_tag {
  font-weight: 1000;
  font-size: 2.375rem;
  color: #000;
}

.try_freeBtnTopText {
  margin: 2px auto;
  margin-top: 25px;
  font-weight: 500;
  font-size: 1.6rem;
}

.aboutus_background {
  background: url('../assets//aboutBack.png');
  background-position: center;
  background-size: cover;
  text-align: center;
  margin: auto;
  width: 75%;
  /* border: 1px solid red; */
}

.about_content {
  color: #4A4B4D;
  font-size: 1rem;
  margin-top: 10px;
  line-height: 28px;
}

.leftside_about {
  margin-top: 20px;
}

.leftside_about article {
  font-size: 1.1rem;
}

.car_div {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
}

.title {
  font-weight: 700;
  font-size: 2rem;

}

.awesome_mainDiv {
  /* background-image: url("../assets/back_light.png");
  background-position: center;
  background-size: cover; */
  padding: 50px 0px;
}

.awesomImgOuTerDiv {
  display: flex;
}

.features_section {
  display: flex;
  flex-direction: column;
  align-items: center;

}

.paragraph {
  font-size: 0.9rem;
}

.section_paragraph {
  display: grid;
  place-items: center;
}

.aboutPadding {
  padding: 4rem 0px;
}


.video {
  width: 60rem;
  border-radius: 9px;
}

.videoDiv {
  text-align: center;
  position: relative;
}

.playIcon {
  position: absolute;
  top: 45%;
  left: 43%;
  cursor: pointer;
}

.pauseIcon {
  position: absolute;
  top: 45%;
  left: 48%;
  cursor: pointer;
}

.playIcon img {
  width: 40%;
}

.anticon-pause {
  color: white !important;
  font-size: 5rem !important;
}

.showMenu_btn {
  display: none;
}

.features_section {
  text-align: center;
}

.logosDIv {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  gap: 28px;
}

.playstre_img {
  width: 50%;
}

.mobiles {
  width: 65%;
}

.contactDiv {
  background-color: #F9FAFC;
  margin-top: 150px;
}

.row_section {
  display: flex;
  text-align: justify;
  justify-content: space-between;
  margin: auto;
  margin-left: 10px;
}

.awsome_innner {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.featuContentDiv {
  text-align: center;
  width: 20%;
  position: relative;
  transition: all 1s ease;
  -o-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -webkit-transition: all 1s ease;
  /* top: 50px; */
}

.featuContentDiv:hover {
  transform: rotate(5deg);

}

.features_image {
  height: 75px;
}

.feature_imgDiv {
  border: 2px solid #2653A2;
  border-radius: 50%;
  background-color: white;
  text-align: center;
  width: 120px;
  height: 120px;
  margin: auto;
  padding: 17px 0px;
}

.col__container {
  display: flex;
  text-align: center;
  margin: auto;
  margin-left: 35px;
}

.service {
  border-radius: 5px;
  margin-right: 20px;
  height: 100%;
}



.contact_button {
  background-color: #0E4A96;
  height: 2.5rem;
  width: 10rem;
  margin-top: 35px;
  text-align: center;
}


.download_section {
  margin-top: 50px;
}


.dwnldContent_parent {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.playestore_button {
  /* height: 80px; */
  /* width: 140px; */
  display: flex;
  gap: 1rem;
  margin-top: 20px;
}

.playestore_button img {
  height: 50px;
}

.form_style {
  background-color: #fff;
  width: 100%;
  border: none;
  padding: 10px;
  border: 1px solid #2653A21A;
}

.messageform_style {
  background-color: #fff;
  width: 100%;
  border: none;
  padding-bottom: 40px;
  resize: none !important;
  border: 1px solid #2653A21A;
}

.contact_Side {
  display: flex;
  align-items: center;
  height: 100%;
}

.contact_form {
  padding-left: 155px !important;
}


.send_button {
  background-color: #0E4A96;
  border-radius: 50px;
  color: #fff;
  border: none;
  height: 38px;
  min-width: 9rem;
  font-weight: 500;
}

.send_button:hover {
  color: #000 !important;
}

.solidApp_logo {
  width: 6%;
}

.bottom {
  font-size: 1rem;
  padding: 8px;
  background-color: #2653A2;
  min-height: 40px;
  text-align: center;
  color: #fff;
}

.heder_space {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.rightside_player {
  height: 100%;
  width: 32rem;
}

.footer_parent {
  padding: 40px 9rem;
}

.footer_logo {
  width: 15%;
}

.footer_link {
  font-size: 1.5rem !important;
  margin-bottom: 25px;
}


.ant-qrcode.css-dev-only-do-not-override-byeoj0 {
  box-shadow: 1px 1px 4px 1px #e1dede;
  width: 190px !important;
  height: 190px !important;
}

.footer_dwnld__btn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  text-align: end;
}

.footer_dwnld__btn img {
  width: 60%;
}

@media screen and (min-width: 1440px) and (max-width: 2560px) {
  /* .mobiles {
    width: 60%;
  } */

  .head_container {
    /* height: 100px; */
  }


  .swap_text {
    top: 45%;

  }

  .logo {
    width: 90%;
    object-fit: contain;
  }

  .heading {
    font-size: 1.1rem;
  }


  .download_button {
    /* height: 42px; */
    width: 140px;
  }

  .heaing_tag {
    font-weight: 1000;
    font-size: 4rem;
  }

  .text_para {
    font-size: 2rem;
  }

  .try_button {
    background-color: #0E4A96;
    height: 2.8rem;
    width: 11rem;
    border-radius: 40px;
    font-size: 1.2rem;
  }

  .title {
    font-style: italic;
    font-weight: 700;
    font-size: 3rem;
  }

  .paragraph {
    font-size: 1rem;
  }

  .react__icon {
    font-size: 4rem;
    font-weight: 900;
    color: #fff
  }

  .slidebutton {
    position: absolute;
    width: 93%;
    /* margin-left: 83px; */

  }

}

@media screen and (min-width: 1024px) and (max-width: 1440px) {
  .logo {
    height: 37px;
    margin-bottom: 5px;
  }

  .editor_choice {
    width: 36%;
  }

  .rightside_player {
    width: 27rem;
  }

  .car_div {
    margin-top: 115px;
  }

  .contact_form {
    padding-left: 37px !important;
  }


  .try_button {
    height: 3rem;
    width: 11rem;
  }

  .slidebutton {
    width: 86%;
    /* margin-left: 89px; */
  }

  .swap_text {
    top: 40%;

  }

  .features_section {
    text-align: center;
    margin-top: 15px;
  }

  .twoplayer_img {
    /* width: 88%; */
  }

}

@media screen and (max-width: 1254px) {

  .download_button {
    height: 25px;
  }
}

@media screen and (min-width: 993px) and (max-width: 1024px) {

  .swap_text {
    top: 40%;

  }

  .form_style {
    width: 80%;
  }

  .messageform_style {
    width: 80%;
  }


  .slidebutton {
    width: 83%;
    /* margin-left: 85px; */
  }
}

@media screen and (max-width:992px) {
  .mobiles {
    width: 40rem;
  }

  .logo {
    height: 56px;
    padding: 11px 0px;
    margin-bottom: 0px
  }


  .video {
    width: 35rem;
  }

  .editor_choice {
    width: 50%;
  }

  .features_image {
    height: 80px;
  }

  .contact_form {
    padding-left: 0px !important;
  }

  .footer_detail {
    text-align: center;
  }

  .footer_dwnld__btn {
    text-align: center;
  }

  .footer_dwnld__btn img {
    width: 57%;
  }

  .qr_codeDiv {
    text-align: -webkit-center;
  }

  .heading_detail {
    display: none !important;
  }

  .messageform_style {
    width: 100%;
  }


  .title {
    text-align: center;
  }

  .common_container {
    padding-inline: 2rem;
  }

  .download_button {
    display: none;
  }

  .showMenu_btn {
    display: flex;
    align-items: center;
  }

  .leftside_about {
    margin-top: 0px;
    margin-bottom: 1rem;
  }



  .playestore_button {
    flex-direction: column;
    /* margin: auto; */
  }


  .heading {
    font-size: 1.2rem;
  }

  .form_style {
    width: 100%;
  }
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
  #form {
    min-width: 74%;
  }

  .mobiles {
    width: 70%;
  }

  .dwnldContent_parent {
    padding-inline: 2rem;
    padding-top: 30px;
  }


  .playestore_button {
    flex-direction: column;
    width: 120px;
    margin: auto;
  }

  .contact_form {
    padding-inline: 2rem;
  }

  .contact_Side {
    display: flex;
    justify-content: center;
  }

  .head_container {
    justify-content: space-between;
    padding-inline: 0rem;
    padding-top: 0.5rem;
  }

  .common_container {
    padding-inline: 2rem;

  }

  .download_butt {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .download_butt>img {
    height: 30%;
    width: 40%;
  }

  .heading {
    font-size: large;
    /* width: 100%; */
    display: flex;
  }


  .leftside_about {
    margin-top: 50px;
    padding-inline: 2rem;
  }

  .react__icon {
    font-size: 3rem;
    font-weight: 900;
    color: #fff
  }


  .swap_text {
    top: 34%;
  }
}

@media screen and (min-width: 425px) and (max-width: 767px) {
  .ant-qrcode.css-dev-only-do-not-override-byeoj0 {
    width: 150px !important;
    height: 150px !important;
  }

  .footer_logo {
    width: 32%;
  }

  .footer_dwnld__btn {
    margin-top: 20px;
  }

  .feature_imgDiv {
    width: 100px;
    height: 100px;
  }

  .footer_dwnld__btn img {
    width: 82%;
  }

  .mobiles {
    width: 100%;
  }

  .video {
    width: 100%;
  }

  .slidebutton {
    width: 92%;
    /* margin-left: 31px; */
  }

  .twoplayer_img {
    width: 100%;
  }

  .swap_text {
    top: 34%;

  }

}


@media screen and (max-width: 541px) {
  .playIcon img {
    width: 25%;
  }

  .anticon-pause {
    color: white !important;
    font-size: 3rem !important;
  }

  .playIcon {
    top: 27%;
    left: 17%;
  }

  .pauseIcon {
    top: 30%;
    left: 43%;
  }

  .features_image {
    height: 55px;
  }

  .footer_logo {
    width: 38%;
  }

  .rightside_player {
    width: 15rem;
  }

  .ant-qrcode.css-dev-only-do-not-override-byeoj0 {
    width: 150px !important;
    height: 150px !important;
  }

  .feature_imgDiv {
    width: 90px;
    height: 90px;
  }



  .mobiles {
    width: 80%;
  }

  .video {
    width: 19rem;
  }

  .heaing_tag {
    font-weight: 700;
    font-size: 1.375rem;
  }

  .swap_text {
    top: 34%;
  }

  .slidebutton {
    position: absolute;
    width: 95%;
  }


  .featuContentDiv {
    width: 32%;
  }

  .editor_choice {
    width: 85%;
    font-size: 1rem;
  }

  .try_freeBtnTopText {
    width: 100%;
  }

  .playstre_img {
    width: 70%;
  }

  .solidApp_logo {
    width: 15%;
  }


  .react__icon {
    font-size: 2rem;
    font-weight: 900;
    color: #fff
  }

  .slidebutton {
    width: 98%;
    /* margin-left: 2px; */
  }

  .playestore_button img {
    height: 68px;
    width: 58%;
    margin: auto;
  }

  .footer_dwnld__btn img {
    width: 45%;
  }

  .footer_link {
    font-size: 1.2rem !important;
    margin-bottom: 15px;
  }

  .center_btn {
    text-align: center;
  }
}

@media screen and (max-width: 320px) {
  .footer_dwnld__btn {
    margin-top: 28px;
  }

  .footer_logo {
    width: 38%;
  }

  .rightside_player {
    width: 16rem;
  }

  .feature_imgDiv {
    width: 80px;
    height: 80px;
  }

  .contactDiv {
    margin-top: 75px;
  }

  .contact_Side {
    padding-top: 30px;
  }

  .mobiles {
    width: 100%;
  }

  .video {
    width: 16rem;
  }
}

.solid_link:hover {
  text-decoration: underline !important;
}